/*!

 =========================================================
 * Material Kit React v1.9.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";

// Custom font from designers
@font-face {
  font-family: "PhosphateSolid";
  src: url("../../fonts/Phosphate/phosphate-solid-02-webfont.woff2")
      format("woff2"),
    url("../../fonts/Phosphate/phosphate-solid-02-webfont.woff") format("woff"),
    url("../../fonts/Phosphate/phosphate-solid-02-webfont.ttf")
      format("truetype"),
    url("../../fonts/Phosphate/phosphate-solid-02-webfont.svg") format("svg"),
    url("../../fonts/Phosphate/phosphate-solid-02-webfont.eot");
  font-weight: normal;
  font-style: normal;
}

// Custom font from designers
@font-face {
  font-family: "AvenirNext";
      src: url("../../fonts/AvenirNext/avenir_next.woff2")
      format("woff2"),
    url("../../fonts/AvenirNext/avenir_next.woff") format("woff"),
    url("../../fonts/AvenirNext/avenir_next.ttf")
      format("truetype"),
    url("../../fonts/AvenirNext/avenir_next.svg") format("svg"),
    url("../../fonts/AvenirNext/avenir_next.eot");    
  font-weight: normal;
  font-style: normal;
}

// Custom font from designers
@font-face {
  font-family: "AvenirNextHeavy";
      src: url("../../fonts/AvenirNextHeavy/avenir_next_heavy.woff2")
      format("woff2"),
    url("../../fonts/AvenirNextHeavy/avenir_next_heavy.woff") format("woff"),
    url("../../fonts/AvenirNextHeavy/avenir_next_heavy.ttf")
      format("truetype"),
    url("../../fonts/AvenirNextHeavy/avenir_next_heavy.svg") format("svg"),
    url("../../fonts/AvenirNextHeavy/avenir_next_heavy.eot");    
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCondensed";
      src: url("../../fonts/AvenirNextCondensed/AvenirNextCondensed-Regular-08.woff2")
      format("woff2"),
    url("../../fonts/AvenirNextCondensed/AvenirNextCondensed-Regular-08.woff") format("woff"),
    url("../..//fonts/AvenirNextCondensed/AvenirNextCondensed-Regular-08.ttf")
      format("truetype"),
    url("../..//fonts/AvenirNextCondensed/AvenirNextCondensed-Regular-08.svg") format("svg"),
    url("../../fonts/AvenirNextCondensed/AvenirNextCondensed-Regular-08.eot");    
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCondensedBold";
      src: url("../../fonts/AvenirNextCondensedBold/AvenirNextCondensed-Bold-01.woff2")
      format("woff2"),
    url("../../fonts/AvenirNextCondensedBold/AvenirNextCondensed-Bold-01.woff") format("woff"),
    url("../../fonts/AvenirNextCondensedBold/AvenirNextCondensed-Bold-01.ttf")
      format("truetype"),
    url("../../fonts/AvenirNextCondensedBold/AvenirNextCondensed-Bold-01.svg") format("svg"),
    url("../../fonts/AvenirNextCondensedBold/AvenirNextCondensed-Bold-01.eot");    
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCondensedHeavy";
      src: url("../../fonts/AvenirNextCondensedHeavy/AvenirNextCondensed-Heavy-09.woff2")
      format("woff2"),
    url("../../fonts/AvenirNextCondensedHeavy/AvenirNextCondensed-Heavy-09.woff") format("woff"),
    url("../../fonts/AvenirNextCondensedHeavy/AvenirNextCondensed-Heavy-09.ttf")
      format("truetype"),
    url("../../fonts/AvenirNextCondensedHeavy/AvenirNextCondensed-Heavy-09.svg") format("svg"),
    url("../../fonts/AvenirNextCondensedHeavy/AvenirNextCondensed-Heavy-09.eot");    
  font-weight: normal;
  font-style: normal;
}